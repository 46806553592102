import React from "react";
  
const Project = () => {
  return (
    <div>
      <h1>
      Project Page
      </h1>
    </div>
  );
};
  
export default Project;