import React from "react";
  
const Blog = () => {
  return (
    <div>
      <h1>
        Blog Page
      </h1>
    </div>
  );
};
  
export default Blog;